// 配置管理
<template>
  <div class="jobs">
    <!-- 1.筛选框 -->
    <div class="screening">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="筛选框" name="1">
          <div class="screening-out">
            <el-form
              :inline="true"
              :model="searchFrom"
              class="demo-form-inline"
            >
              <el-row>
                <el-col :span="22">
                  <el-form-item label="配置名称">
                    <el-input
                      v-model="searchFrom.name"
                      placeholder="请输入需要查询的配置名称"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="所属部门">
                                        <el-input v-model="searchFrom.name" placeholder="请输入需要查询的部门名称"  size="mini"></el-input>
                                    </el-form-item> -->
                  <!-- <el-form-item label="创建时间">
                                        <el-date-picker
                                            v-model="valueTime"
                                            type="datetimerange"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            clearable
                                            value-format="YYYY-MM-DD HH:mm:ss"
                                             size="mini"
                                            :default-time="defaultTime">
                                        </el-date-picker>
                                    </el-form-item> -->
                </el-col>
                <el-col :span="2">
                  <!-- <el-form-item> -->
                  <div class="title-buttom">
                    <el-button class="cancel" size="mini" @click="reset" round
                      >重置</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      class="confirmAdd"
                      @click="getDataList(true)"
                      round
                      >查询</el-button
                    >
                  </div>
                  <!-- </el-form-item> -->
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-row>
      <el-col :span="12">
        <div class="title-name">配置列表</div>
      </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <el-tooltip content="新增配置" placement="top" effect="light">
            <el-button
              class="editor"
              size="mini"
              icon="el-icon-plus"
              @click="addData"
              circle
            ></el-button>
          </el-tooltip>
          <el-tooltip content="刷新列表" placement="top" effect="light">
            <el-button
              class="editor"
              size="mini"
              icon="el-icon-refresh-right"
              @click="reset"
              circle
            ></el-button>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-table
            v-if="tableShow"
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :max-height="tableHeight"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            @selection-change="handleSelectionChange"
          >
            <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
            <el-table-column label="名称" width="220">
              <template #default="scope">{{
                scope.row.name == null ? "-" : scope.row.name
              }}</template>
            </el-table-column>
            <el-table-column label="键" width="220">
              <template #default="scope"
                >{{ scope.row.key == null ? "-" : scope.row.key }}
              </template>
            </el-table-column>
            <el-table-column label="值">
              <template #default="scope">
                <el-popover
                  placement="bottom-start"
                  :disabled="checkDisabled(scope.row.key)"
                  :show-arrow="false"
                  :width="'45vw'"
                  trigger="hover"
                >
                  <template #reference>
                    <div class="nowarp" :id="scope.row.key">
                      {{ scope.row.value == null ? "-" : scope.row.value }}
                    </div>
                  </template>
                  <template #default>
                    <div class="poppermaxheight">
                      {{ scope.row.value }}
                    </div>
                  </template>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template #default="scope">{{
                scope.row.createTime == null ? "-" : scope.row.createTime
              }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="150">
              <template #default="scope">
                <el-tooltip content="编辑配置" placement="top" effect="light">
                  <el-button
                    class="editor"
                    size="mini"
                    icon="el-icon-edit"
                    @click="modify(scope.row)"
                    round
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除配置" placement="top" effect="light">
                  <el-button
                    class="deletetable"
                    size="mini"
                    icon="el-icon-delete"
                    @click="confirmEvent(scope.row.key)"
                    round
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="pager">
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          :page-sizes="[10, 20, 30, 40]"
          :page-size="searchFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <div class="popup-view">
      <el-form
        label-position="top"
        label-width="100px"
        :model="upform"
        :rules="rules"
        ref="upform"
      >
        <el-dialog
          :title="popupTitle == 0 ? '新增配置' : '修改配置'"
          v-model="centerDialogVisible"
          width="50%"
          @close="closeDialog"
        >
          <!-- 图标展示 -->
          <div class="popup-view">
            <el-dialog
              width="30%"
              title="内弹窗"
              v-model="innerVisible"
              append-to-body
            >
              <template #footer>
                <el-form-item>
                  <el-button
                    class="cancel"
                    plain
                    @click="innerVisible = false"
                    round
                    >取消</el-button
                  >
                  <el-button class="confirmAdd" round>确认</el-button>
                </el-form-item>
              </template>
            </el-dialog>
          </div>
          <div class="center-body">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="名称：" prop="name">
                  <el-input v-model="upform.name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="键：" prop="key">
                  <el-input v-model="upform.key"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="值：" prop="value">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    :autosize="{ minRows: 4, maxRows: 6 }"
                    v-model="upform.value"
                    show-word-limit
                    ref="input"
                    rows="11"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="备注：">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    :autosize="{ minRows: 4, maxRows: 6 }"
                    v-model="upform.remark"
                    maxlength="30"
                    show-word-limit
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="resetForm('upform')" round
                >取消</el-button
              >
              <el-button
                class="confirmAdd"
                @click="submitForm('upform')"
                round
                >{{ popupTitle == 0 ? "新增" : "修改" }}</el-button
              >
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
  </div>
</template>

<script>
import config from "@/api/system/config";
import { IconFont } from "@/utils/iconfont";
export default {
  components: {
    IconFont,
  },
  data() {
    return {
      tableShow: true,
      pageChange: false,
      tableHeight: "",
      searchFrom: {
        page: 1,
        pageSize: 10,
        name: "",
        createTimeBegin: "",
        createTimeEnd: "",
      }, //搜索值
      currentPage: 1, //分页
      total: 0,
      multipleSelection: [], //选中集合
      valueTime: "", //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 12, 0, 0),
        new Date(2000, 2, 1, 8, 0, 0),
      ], //默认时间 '12:00:00', '08:00:00'
      activeNames: [], //折叠面板
      tableData: [], //数据列表
      loading: true, // loding 状态启用
      popupTitle: 0, //弹窗类别
      centerDialogVisible: false, //弹窗启用
      innerVisible: false, //图标弹窗启用
      upform: {
        //配置信息
        name: "", //配置名称
        key: "",
        remark: "",
        value: "",
      },
      rules: {
        name: [{ required: true, message: "请输入配置名称", trigger: "blur" }],
        key: [{ required: true, message: "请输入配置键", trigger: "blur" }],
        value: [{ required: true, message: "请输入配置值", trigger: "blur" }],
      },
      timeOutId: null,
    };
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight;
  },
  watch: {},
  mounted() {
    this.getDataList(true);
  },
  methods: {
    // 重置
    reset() {
      this.valueTime = [];
      this.searchFrom = {
        page: this.currentPage,
        pageSize: 10,
      };
      this.getDataList(true);
    },
    // 获取配置列表
    getDataList(type) {
      this.loading = type;
      this.searchFrom.createTimeBegin = this.valueTime[0];
      this.searchFrom.createTimeEnd = this.valueTime[1];
      config
        .configList({
          ...this.searchFrom,
        })
        .then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.loading = false;
        });
    },
    // 分页
    handleSizeChange(val) {
      this.searchFrom.pageSize = val;
      this.getDataList(true);
    },
    handleCurrentChange(val) {
      this.searchFrom.page = val;
      this.currentPage = val;
      this.pageChange = true;
      this.getDataList(true);
    },
    // 折叠面板
    handleChange(val) {
      console.log(val);
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量删除
    deleteData() {
      console.log(this.multipleSelection);
    },
    // 新增
    addData() {
      this.popupTitle = 0;
      this.centerDialogVisible = true;
    },
    // 修改配置
    modify(row) {
      //值这一栏自动获取焦点
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      },0);

      this.upform = JSON.parse(JSON.stringify(row));
      this.popupTitle = 1;
      this.centerDialogVisible = true;
    },
    // 删除确认
    confirmEvent(key) {
      this.$confirm("将删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          config
            .deleteConfig({
              key: key,
            })
            .then((res) => {
              this.$notify({
                title: "成功",
                message: "配置删除成功！",
                type: "success",
              });
              this.getDataList(true);
            });
        })
        .catch(() => {
          this.$notify.info({
            title: "通知",
            message: "已取消删除!",
          });
        });
    },
    // 取消
    cancelEvent() {
      console.log("cancel!");
    },
    // 表单提交
    submitForm(formName) {
      // this.upform.departmentId = this.value[this.value.length-1]
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.popupTitle == 0) {
            config
              .addConfig({
                ...this.upform,
              })
              .then((res) => {
                this.$notify({
                  title: "成功",
                  message: "配置添加成功！",
                  type: "success",
                });
                this.getDataList(true);
                this.centerDialogVisible = false;
              });
          } else {
            let str = this.upform.value;
            str = str.replace(/,,/g, ",");
            str = str.replace(/::/g, ":");
            this.upform.value = str;
            console.log(this.upform.value);
            config
              .editConfig({
                ...this.upform,
              })
              .then((res) => {
                this.$notify({
                  title: "成功",
                  message: "配置修改成功！",
                  type: "success",
                });
                this.getDataList(true);
                this.centerDialogVisible = false;
              });
          }
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.value = [];
      this.centerDialogVisible = false;
    },
    closeDialog() {
      this.$refs.upform.resetFields();
      this.value = [];
      this.centerDialogVisible = false;
    },
    checkDisabled(key) {
      if (key) {
        let ele = document.getElementById(key);
        if (ele) {
          return ele.scrollHeight <= ele.offsetHeight;
        } else {
          if (!this.timeOutId && this.pageChange) {
            this.pageChange = false;
            this.timeOutId = setTimeout(() => {
              // this.reset()
              this.tableShow = false;
              this.$nextTick(() => {
                this.tableShow = true;
                this.timeOutId = null;
              });
            }, 100);
          }
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pager {
  display: flex;
  justify-content: flex-end;
}
.jobs {
  height: 100%;
  overflow-y: overlay;
  padding-right: 18px;
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}
.space-tile {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.center-body {
  margin: 0 1.875rem;
}
.deletetable {
  color: #ff845b;
  background: #ffe4db;
}
.deletetable-other {
  margin-right: 1.25rem;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0 0.9375rem;
}
.showIcon {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  overflow-y: overlay;
  height: 28.125rem;
  .out-icon {
    border-radius: 0.5rem;
    .icon-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 3rem;
    }
    .icon-name {
      text-align: center;
      width: 4rem;
      height: 2.5rem;
      line-height: 2.5rem;
    }
  }
}
.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}
.nowarp {
  overflow: hidden;
  // text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // position: relative;
  // &::after{
  //   content:'···';
  //   position: absolute;
  //   right: 0;
  //   bottom: 0;
  //   font-size: 16px;
  //   font-weight: bold;
  //   color: #0170ed;
  // }
}
.poppermaxheight {
  max-height: 200px;
  overflow: auto;
  width: 43vw !important;
}
</style>

<style lang="scss">
.jobs .el-collapse-item__content {
  padding-bottom: 0 !important;
}
// .jobs .el-button {
//     // border-color: #FFFFFF !important;
// }
</style>