// 配置管理
import { Request } from '@/http/request'

// 获取配置
export function configList (parameter: any) {
  return Request.axiosInstance.post('/system/config/list', parameter)
}
// 修改配置 
export function editConfig (parameter: any) {
    return Request.axiosInstance.post('/system/config/edit', parameter)
}
// 修改配置 
export function deleteConfig (parameter: any) {
    return Request.axiosInstance.post('/system/config/delete', parameter)
}
// 修改配置 
export function addConfig (parameter: any) {
    return Request.axiosInstance.post('/system/config/add', parameter)
}

export default { configList, editConfig, deleteConfig, addConfig }
